import { useCallback } from "react";

import { useSite } from "~/contexts";
import { useCurrency } from "~/lib/utils/currency";

export const useFormatPrice = () => {
  const { locale } = useSite();
  const currency = useCurrency();
  return useCallback(
    (price: number | undefined | null) => {
      if (!price && price !== 0) return;
      return price.toLocaleString(locale.alias, {
        style: "currency",
        currency,
      });
    },
    [locale.alias, currency],
  );
};
